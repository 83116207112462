/* .resume-pic {
    margin-top: 6vh;
}

#Resume {
    margin-top: -3vh;
} */


.resume-pic {
    margin-top: 6vh;
}

#Resume {
    margin-top: -3vh;
}

.pdf-embed {
    height: 90vh;
    max-width: 55vw;
    max-height: 100vh;
}