.App {
  text-align: center;

}

body {
  background-color: rgb(99, 134, 103);
  overflow-y: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}


.my-pic {
  flex-wrap: wrap;
  position: absolute;
  /* Position the image absolutely */
  width: auto;
  /* Allow the width to adjust automatically */
  width: 35%;
  /* Set the width to 100% to make it responsive */
  aspect-ratio: 1/1;
  /* Set the aspect ratio to 1:1 for a square */
  object-fit: cover;
  top: 20%;
  left: 59%;
}

.welcome-container {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 30%;
  left: 5%;
}

.different-color {
  color: #BABABA;
}

.line1 {
  font-size: 25px;
  /* font-family: 'Inter'; */
  font-weight: 700;
  word-wrap: break-word;
}

.name-black {
  color: black;
}

.line2-3 {
  color: black;
  font-size: 70px;
  /* font-family: 'Inter'; */
  font-weight: 700;
  word-wrap: break-word;
}

.line3 {
  color: black;
  font-size: 70px;
  /* font-family: 'Inter'; */
  font-weight: 700;
  word-wrap: break-word;
}



.contact-container {
  width: 15vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 55%;
  left: 5%;
}

.contact-header {
  font-size: 40px;
  /* font-family: 'Inter'; */
  font-weight: 700;
  word-wrap: break-word;
}

.email {
  font-size: 15px;
  /* font-family: 'Inter'; */
  font-weight: 700;
  word-wrap: break-word;
}

.phoneNum {
  font-size: 15px;
  /* font-family: 'Inter'; */
  font-weight: 700;
  word-wrap: break-word;
}

.linkedin-logo {
  flex-wrap: wrap;
  position: absolute;
  /* Position the image absolutely */
  width: auto;
  /* Allow the width to adjust automatically */
  /* height: 40px; Set the desired height */
  height: 4.2vh;
  top: 110%;
}

.github-logo {
  flex-wrap: wrap;
  position: absolute;
  /* Position the image absolutely */
  width: auto;
  /* Allow the width to adjust automatically */
  height: 4.2vh;
  /* Set the desired height */
  right: 65%;
  top: 110%;
}