.enlarged-image {
    overflow: hidden;
    width: 50vw;
    height: 100vh;
    margin-left: auto;
    position: fixed;
    top: 0;
    left: 60vw;
    z-index: -1;
}

.my-other-pic {
    width: 50vw;
    height: 100vh;
    object-fit: cover;
    display: block;
    margin: 0;
}

.header-container {
    transform: rotate(90deg);
    width: 40vw;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 22.1vw;
    right: 75.9vw;
}

.about-me-header {
    font-size: 7.2rem;
    font-weight: 700;
}

.paragraph-container {
    width: 35vw;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 12vh;
    left: 15.1vw;
    flex-shrink: 0;

}

.paragraph-highlight-color {
    color: #BABABA;
}

.text {
    font-size: 1.25rem;
    font-weight: 650;
    margin-bottom: 1.6vh;
    text-align: left;
}

.text2 {
    font-size: 1.25rem;
    font-weight: 650;
    margin-bottom: 1.6vh;
    text-align: left;
}

.text3 {
    font-size: 1.25rem;
    font-weight: 650;
    text-align: left;
}

.skills-container {
    /* margin-top: 49vh; */
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    text-align: left;

}

.skills-header {
    font-size: 5vh;
    font-weight: 700;

}

/*
.skill-badges-container {
    margin-top: 3%; */
/* margin-top: 59vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: absolute; */
/* top: 110%; */
/* top: 51.8vh; */
/* background-color: bisques
}
*/
.skills {
    font-size: 1.06rem;
    font-weight: 600;
    color: rgb(39, 70, 58)
}